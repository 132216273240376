<template>
  <v-list-item
    :to="{
      name: 'shopProductDetail',
      params: {
        id,
        productId,
      },
      query: {
        ...$route.query,
      },
    }"
    v-if="image || title || description"
  >
    <v-list-item-avatar tile size="80" v-if="image">
      <v-img
        :lazy-src="$config.managerMedia + 'picturepool/' + image"
        :src="$config.managerMedia + 'picturepool/' + image"
      ></v-img>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title v-html="title"></v-list-item-title>
      <v-list-item-subtitle>
        {{ description }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action class="font-weight-bold ml-2">
      {{ price | currency($store.state.session.currencyConfig) }}
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
    groupId: {
      type: [String, Number],
      default: "",
    },
    productId: {
      type: [String, Number],
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    price: {
      type: [Number, String],
      default: "",
    },
  },
};
</script>
