var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.image || _vm.title || _vm.description)?_c('v-list-item',{attrs:{"to":{
    name: 'shopProductDetail',
    params: {
      id: _vm.id,
      productId: _vm.productId,
    },
    query: {
      ..._vm.$route.query,
    },
  }}},[(_vm.image)?_c('v-list-item-avatar',{attrs:{"tile":"","size":"80"}},[_c('v-img',{attrs:{"lazy-src":_vm.$config.managerMedia + 'picturepool/' + _vm.image,"src":_vm.$config.managerMedia + 'picturepool/' + _vm.image}})],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.description)+" ")])],1),_c('v-list-item-action',{staticClass:"font-weight-bold ml-2"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.price,_vm.$store.state.session.currencyConfig))+" ")])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }