<template>
  <v-card flat>
    <v-card-title>{{ title }}</v-card-title>
    <v-card-subtitle v-if="description" v-html="description"></v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
