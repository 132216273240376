<template>
  <v-list-item
    :to="{
      name: 'shop',
      query: {
        groups: [...groups, groupId],
      },
    }"
    exact
    :key="title"
  >
    <v-list-item-avatar tile size="80" v-if="image">
      <v-img
        :lazy-src="$config.managerMedia + 'picturepool/' + image"
        :src="$config.managerMedia + 'picturepool/' + image"
      >
      </v-img>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title v-html="title"></v-list-item-title>
      <v-list-item-subtitle v-html="description"></v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <v-icon>mdi-chevron-right</v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  computed: {
    groups() {
      let { groups = [] } = this.$route.query;
      if (typeof groups !== "object") {
        groups = [groups];
      }

      return groups;
    },
  },
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
    groupId: {
      type: [String, Number],
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    sub: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
